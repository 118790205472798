// Functionality: API Call for Scope 1 Emissions Data Points (Chart)
import axios from "../loadBalancerAxios";
import { LIFECYCLE_EMISSIONS_URL } from "../paths";
import {
  wellToTankDataConfig,
  tankToWheelDataConfig,
  wellToWheelDataConfig,
} from "../../constants/EmissionsConfig";
import useDateFormat from "../../hooks/use-DateFormat";
import useFindYearChange from "../../hooks/use-FindYearChange";

export const getLifeCycleEmissionsAPI = async (
  setData,
  selectedFuelType,
  selectedChartRadioItemType,
  applicationDates
) => {
  // Const: Determine if the data is daily or monthly
  const isDaily = selectedChartRadioItemType === "Daily";

  // Const: Determine the fuel types to be used in the API call (filtered)
  const fuelTypes =
    selectedFuelType?.length > 0
      ? `&fuel_type=${selectedFuelType
          ?.map((item) => {
            return item.value;
          })
          .join(",")}`
      : "";

  // Const: API Call Path
  const LIFECYCLE_EMISSIONS_DATE_URL = isDaily
    ? `${LIFECYCLE_EMISSIONS_URL}?format=Daily&date=${applicationDates?.lifecycleChart}`
    : `${LIFECYCLE_EMISSIONS_URL}?format=Monthly&year=${applicationDates?.lifecycleChartYearly}`;

  await axios
    .get(`${LIFECYCLE_EMISSIONS_DATE_URL}${fuelTypes}`)
    .then((response) => {
      // Const: Set Scope 2 Emissions Data
      const responseData = response?.data || [];

      // Const: Length of Data, Check if Daily or Monthly Data
      const lengthData = responseData?.date?.length;
      const nonEmptyNonDailyData = lengthData > 1 && !isDaily;

      const indexofLineBreak = useFindYearChange(responseData?.date);

      const combinedValues = responseData?.tank_to_wheel
        ?.concat(responseData?.well_to_tank)
        ?.concat(responseData?.well_to_wheel);

      const maxValue = Math.max(...combinedValues) + 50;

      const annotationValue = {
        first: responseData?.date
          ?.slice(indexofLineBreak - 1)?.[0]
          ?.slice(0, 4),
        second: responseData?.date?.slice(indexofLineBreak)?.[0]?.slice(0, 4),
      };

      // Const: Set Formatted Data
      const formattedTankWheelData = responseData?.date?.map((x, index) => ({
        x: index,
        y: responseData?.tank_to_wheel[index],
        label: nonEmptyNonDailyData
          ? useDateFormat(x)
          : useDateFormat(x, "month-day"),
        date: x,
      }));
      const formattedWellTankData = responseData?.date?.map((x, index) => ({
        x: index,
        y: responseData?.well_to_tank[index],
        label: nonEmptyNonDailyData
          ? useDateFormat(x)
          : useDateFormat(x, "month-day"),
        date: x,
      }));
      const formattedWellWheelkData = responseData?.date?.map((x, index) => ({
        x: index,
        y: responseData?.well_to_wheel[index],
        label: nonEmptyNonDailyData
          ? useDateFormat(x)
          : useDateFormat(x, "month-day"),
        date: x,
      }));

      setData({
        loaded: true,
        data: [
          {
            ...wellToTankDataConfig,
            dataValues: formattedWellTankData,
            sum: responseData?.tank_to_wheel.reduce(
              (acc, curr) => acc + curr,
              0
            ),
          },
          {
            ...tankToWheelDataConfig,
            dataValues: formattedTankWheelData,
            sum: responseData?.well_to_tank.reduce(
              (acc, curr) => acc + curr,
              0
            ),
          },
          {
            ...wellToWheelDataConfig,
            dataValues: formattedWellWheelkData,
            sum: responseData?.well_to_wheel.reduce(
              (acc, curr) => acc + curr,
              0
            ),
          },
        ],
        lineBreak:
          nonEmptyNonDailyData && indexofLineBreak !== -1
            ? indexofLineBreak - 1
            : false,
        maxValue: maxValue,
        annotationValue: annotationValue,
      });
    })
    .catch(() => {
      setData({
        loaded: true,
        data: [
          { ...wellToTankDataConfig, dataValues: [], sum: 0 },
          {
            ...tankToWheelDataConfig,
            dataValues: [],
            sum: 0,
          },
          {
            ...wellToWheelDataConfig,
            dataValues: [],
            sum: 0,
          },
        ],
        lineBreak: false,
        maxValue: false,
        annotationValue: false,
      });
    });
};
