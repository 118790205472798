import { useEffect } from "react";
import MetricTileGroupMini from "../../../components/MetricTileGroupMini";
import Skeleton from "react-loading-skeleton";

export default function GlecFrameworkCards({
  wellToTankData,
  tankToWheelData,
  wellToWheelData,
  applicationDates,
  glecLifeCycleChartData,
  setWellToWheelData,
  setTanktoWheelData,
  setWellToTankData,
}) {
  //  UE: Get GLEC Life Cycle Data For Application(Trigger API Call) When applicationDates Changes
  useEffect(() => {
    if (glecLifeCycleChartData?.loaded) {
      const result = glecLifeCycleChartData?.data[0]?.sum;
      const result2 = glecLifeCycleChartData?.data[1]?.sum;
      const result3 = glecLifeCycleChartData?.data[2]?.sum;

      setWellToWheelData({
        ...wellToWheelData,
        value:
          result3 !== undefined
            ? result3
                .toFixed(3)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "-",
        loaded: true,
      });
      setTanktoWheelData({
        ...tankToWheelData,
        value:
          result2 !== undefined
            ? result2
                ?.toFixed(3)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "-",
        loaded: true,
      });
      setWellToTankData({
        ...wellToTankData,
        value:
          result !== undefined
            ? result
                ?.toFixed(3)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "-",
        loaded: true,
      });
    }
  }, [
    applicationDates?.lifecycleChart,
    applicationDates?.lifecycleChartYearly,
    glecLifeCycleChartData,
  ]);

  return (
    <div className="life-glec-sidebar-group">
      <div className="life-glec-card-group">
        {wellToWheelData?.loaded ? (
          <MetricTileGroupMini svgConfig={wellToTankData} />
        ) : (
          <Skeleton height={100} />
        )}
        {tankToWheelData?.loaded ? (
          <MetricTileGroupMini svgConfig={tankToWheelData} />
        ) : (
          <Skeleton height={100} />
        )}
        {wellToWheelData?.loaded ? (
          <MetricTileGroupMini svgConfig={wellToWheelData} />
        ) : (
          <Skeleton height={100} />
        )}
      </div>
    </div>
  );
}
