// Functionality: API Call for Emissions Footprint Tile
import axios from "../loadBalancerAxios";
import { TOTAL_EMISSION_FOOTPRINT_URL } from "../paths";
import {
  totalEmissionFootprintSVGConfig,
  totalEmissionFootprintBreakdownConfig,
} from "../../constants/EmissionsConfig";

export const postTotalEmissionFootprintAPI = async (
  setData,
  selectedDate,
  setEmiData
) => {
  await axios
    .post(TOTAL_EMISSION_FOOTPRINT_URL, {
      date: selectedDate,
    })
    .then((response) => {
      // Const: Set Total Emission Footprint Data
      const scope1ResponseData = response?.data?.scope1;
      const scope2ResponseData = response?.data?.scope2;
      const scope3ResponseData = response?.data?.scope3;

      const sumScopeEmissions =
        scope1ResponseData + scope2ResponseData + scope3ResponseData;

      setData({
        ...totalEmissionFootprintSVGConfig,
        value: Math.round(sumScopeEmissions?.toFixed(3) * 100) / 100,
        loaded: true,
      });

      totalEmissionFootprintBreakdownConfig.scope1.percentageValue = (
        scope1ResponseData / sumScopeEmissions
      )?.toFixed(4);
      totalEmissionFootprintBreakdownConfig.scope2.percentageValue = (
        scope2ResponseData / sumScopeEmissions
      ).toFixed(4);
      totalEmissionFootprintBreakdownConfig.scope3.percentageValue = (
        scope3ResponseData / sumScopeEmissions
      ).toFixed(4);

      setEmiData({
        ...totalEmissionFootprintBreakdownConfig,
        dataAvailable: true,
      });
    })
    .catch(() => {
      // Const: Set Emission Footprint Data to "-" when null
      const responseData = "-";
      setData({
        ...totalEmissionFootprintSVGConfig,
        value: responseData,
        loaded: true,
      });
    });
};
