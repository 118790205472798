// Functionality: API Call for Vehicle Fuel Type Options in Vehicle Settings Edit
import axios from "../loadBalancerAxios";
import { FUEL_TYPE_OPTIONS_URL } from "../paths";

export const getFuelTypeOptions = async (
  setData,
  filterBio = false,
  sizeFull = false
) => {
  const fuelTypesURL = sizeFull
    ? `${FUEL_TYPE_OPTIONS_URL}?size=full`
    : FUEL_TYPE_OPTIONS_URL;
  await axios
    .get(fuelTypesURL)
    .then((response) => {
      // Const: Response Data
      const responseData = response?.data.sort();

      // Fiter BioDisesel
      const filterData = filterBio
        ? responseData.filter((item) => item !== "Biodiesel")
        : responseData;

      // Response Data Mapped
      const mappedResponseData = filterData.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      setData(mappedResponseData);
    })
    .catch(() => {});
};
