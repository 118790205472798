import { useContext } from "react";
import EmissionsContext from "../../context/EmissionsProvider";
import DateContext from "../../context/DateProvider";
import GlecFrameworkCards from "./LifeCycleTab/GlecFrameworkCards";
import GlecFrameworkVisual from "./LifeCycleTab/GlecFrameworkVisual";

export default function LifeCyclePage() {
  // Const: Initialize Emissions Context
  const {
    glecLifeCycleChartData,
    wellToTankData,
    tankToWheelData,
    wellToWheelData,
    setWellToWheelData,
    setTanktoWheelData,
    setWellToTankData,
    vehicleFuelTypeOptions,
    selectedFuelType,
    setSelectedFuelType,
  } = useContext(EmissionsContext);

  // Const: Initialize Context - Dates
  const { dateValues, applicationDates, setApplicationDates, yearValues } =
    useContext(DateContext);

  return (
    <div className="life-glec-page-group">
      <GlecFrameworkCards
        wellToTankData={wellToTankData}
        tankToWheelData={tankToWheelData}
        wellToWheelData={wellToWheelData}
        applicationDates={applicationDates}
        glecLifeCycleChartData={glecLifeCycleChartData}
        setTanktoWheelData={setTanktoWheelData}
        setWellToTankData={setWellToTankData}
        setWellToWheelData={setWellToWheelData}
      />
      <GlecFrameworkVisual
        glecLifeCycleChartData={glecLifeCycleChartData}
        vehicleFuelTypeOptions={vehicleFuelTypeOptions}
        selectedFuelType={selectedFuelType}
        setSelectedFuelType={setSelectedFuelType}
        dateValues={dateValues}
        applicationDates={applicationDates}
        setApplicationDates={setApplicationDates}
        yearValues={yearValues}
      />
    </div>
  );
}
