// Functionality: This is the VehicleProfile component of VehiclePerformancePage.
import { useEffect, useContext, useRef } from "react";
import VehiclePerformanceContext from "../../context/VehiclePerformanceProvider";
import DateContext from "../../context/DateProvider";
import { AuthContext } from "../../context/AuthProvider";
import useQueryParams from "../../hooks/use-queryParams";
import { PiTruckLight } from "react-icons/pi";
import { IoIosLock } from "react-icons/io";
import { TfiDownload } from "react-icons/tfi";
import { FaArrowLeftLong } from "react-icons/fa6";
import GaugeChart from "../../visualizations/GaugeChart";
import useLabelCarbonRating from "../../hooks/use-labelCarbonRating";
import useNavHook from "../../hooks/use-NavHook";
import { vehiclePerformanceProfileDataLabels } from "../../constants/VehicleConfig";
import { getVehicleProfileAPI } from "../../api/VehiclePerformance/VehicleProfilesAPI";
import SwitchCarousel from "../../components/SwitchCarousel";
import { driveStatusVehicleProfile } from "../../constants/TileConfig";
import logoImage from "../../assets/logo-green-black.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Button from "../../components/Button";

export default function VehicleProfile({ setActiveTab, activeTab, mockData }) {
  // Context: Get Values from Vehicle Performance Context
  const { selectedVehicleData, setSelectedVehicleData, vehicleProfileData } =
    useContext(VehiclePerformanceContext);
  // Context: Get Values from Date Context
  const { dateValues, applicationDates, setApplicationDates } =
    useContext(DateContext);
  // Context: Carrier Name
  const { carrierName } = useContext(AuthContext);
  // Get the query parameters from the URL for the username
  const query = useQueryParams();
  const selectedVehicle = query.get("vehicle-profile") || null;
  const profileContentRef = useRef(null);

  // Function: Handle Download BOTH Click
  const handleDownloadProfileAsPDF = (profileContentRef) => {
    if (!profileContentRef.current) {
      console.error("Modal content reference is not set.");
      return;
    }

    html2canvas(profileContentRef.current, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png"); // Convert the canvas to an image
      const pdf = new jsPDF("landscape", "pt", "a4"); // Set orientation to "landscape"

      // PDF dimensions
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Add padding and adjust scaling
      const padding = 40; // Adjust this value for left and right padding
      const ratio = Math.min(
        (pdfWidth - 2 * padding) / canvasWidth, // Account for left/right padding
        pdfHeight / canvasHeight
      );

      const imgWidth = canvasWidth * ratio;
      const imgHeight = canvasHeight * ratio;

      // Adjust offsets for padding and alignment
      const xOffset = padding; // Left padding for the main image
      const yOffset = 120; // Position for the image below the title

      // Calculate logo position: top-right corner
      const logoWidth = 130; // Logo width
      const logoHeight = 30; // Logo height
      const logoX = pdfWidth - logoWidth - 20; // Position logo 20 points from the right edge
      const logoY = 20; // Position logo 20 points from the top edge

      // Add logo image to the top-right corner
      pdf.addImage(logoImage, "JPEG", logoX, logoY, logoWidth, logoHeight);

      // Add left-aligned title
      pdf.setFont("Helvetica", "bold");
      pdf.setFontSize(16);
      pdf.text(
        `${carrierName} - Vehicle ${selectedVehicle.toString()} Profile`,
        40,
        50
      ); // Left-aligned at (40, 50)

      // Parse the input date in yyyy-MM format
      const [year, month, day] = applicationDates?.vehicleProfile.split("-");
      const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1);
      const fullMonth = date.toLocaleString("en-US", { month: "long" });

      // Add subheader (slightly smaller font size and a gap below the title)
      pdf.setFont("Helvetica", "normal");
      pdf.setFontSize(14); // Slightly smaller font size for the subheader
      const subheaderText = `${fullMonth} ${year}`; // Replace with your actual subheader text
      const subheaderY = 70; // Position it 20 units below the main title

      pdf.text(subheaderText, 40, subheaderY); // Left-aligned at (40, 70)

      // Add the image to the PDF (after title and logo)
      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

      // Save the PDF
      pdf.save(`${carrierName}_vehicle${selectedVehicle.toString()}.pdf`);
    });
  };

  // UE: Get Vehicle Profile Data For Selected Vehicle
  useEffect(() => {
    if (
      !selectedVehicle?.loaded &&
      !mockData &&
      applicationDates?.vehicleProfile
    ) {
      getVehicleProfileAPI(
        selectedVehicle,
        applicationDates,
        setSelectedVehicleData
      );
    }
  }, [applicationDates?.vehicleProfile]);

  // UE: Get Vehicle Profile Data For Selected Vehicle IN MOCK VERSIONING
  useEffect(() => {
    if (mockData && vehicleProfileData?.data?.length > 0) {
      const filteredData = vehicleProfileData?.data?.filter((item) => {
        return item.vehicle_id === selectedVehicle;
      });
      setSelectedVehicleData({ data: filteredData?.[0], loaded: true });
    }
  }, [vehicleProfileData]);

  // Deterine Label for Carbon Rating
  const carbonRatingLabel = useLabelCarbonRating(
    selectedVehicleData?.data?.rating
  );

  // Const: Initialize Hook - Navigation Hook to go to Vehicle Settings
  const { navigate, navTo } = useNavHook("/settings?activeTab=1");

  // Function: Handle Navigation to Vehicle Settings
  const handleNavVehicleSettings = () => {
    setActiveTab({ ...activeTab, "/settings": 1 });
    navigate(navTo, { state: { activeTab: 1 }, replace: true });
  };

  const vehInfoDesign = (data, dictionary, item, showLock = false) => {
    return (
      <>
        <div
          className={"vehicle-profile-info-group1"}
          key={dictionary?.[item]?.label}
        >
          <div className="vehicle-profile-info-group-label">
            {dictionary?.[item]?.label}:
          </div>
          <div className="vehicle-profile-info-group-value">
            {dictionary?.[item]?.unitBefore ? dictionary?.[item]?.unit : ""}{" "}
            {data?.data?.[item] || "-"}{" "}
            {dictionary?.[item]?.unitBefore ? "" : dictionary?.[item]?.unit}
          </div>
          {dictionary?.[item]?.blurb?.blurb || <> </>}
          {showLock && (
            <IoIosLock className="vehicle-profile-info-group-lock" />
          )}
        </div>
      </>
    );
  };

  // Set Selected Vehicle
  const handleBackVehicleGroupPage = () => {
    navigate("/vehicle-performance?activeTab=2", {
      state: { activeTab: 2 },
      replace: true,
    });
  };
  return (
    <>
      <div className="vehicle-profile-header-group">
        <div className="flex-row-space-between">
          <div className="vehicle-profile-back-group">
            <FaArrowLeftLong
              className="back-arrow-icon"
              onClick={() => handleBackVehicleGroupPage()}
            />
            <p onClick={() => handleBackVehicleGroupPage()}>
              Back to Vehicle Selection
            </p>
          </div>
          {/* <Button
            buttonName={
              <>
                <p>Download Profile</p>
                <TfiDownload />
              </>
            }
            onClick={() => handleDownloadProfileAsPDF(profileContentRef)}
            className="primary-white-button"
          /> */}
        </div>
        <div className="vp-profile-icon-group">
          <div className="vp-profile-group-switch-carousel">
            <SwitchCarousel
              values={dateValues}
              applicationDates={applicationDates}
              setApplicationDates={setApplicationDates}
              applicationDatesLabel={"vehicleProfile"}
              fillArrowColor={"#66C743"}
              switchCarouselValueClassName="group-switch-carousel-value"
              changeVehicleProfile={true}
            />
          </div>
        </div>
      </div>
      <div className="vehicle-profile-page" ref={profileContentRef}>
        {!selectedVehicleData?.loaded ? (
          <></>
        ) : (
          <>
            <div className="vehicle-profile-card1">
              <div className="vehicle-profile-card-title">
                Vehicle {selectedVehicle.toString()}
              </div>
              <PiTruckLight className="vehicle-profile-truck-icon" />
              <div className="vehicle-profile-card1-content">
                {Object.keys(vehiclePerformanceProfileDataLabels?.profile)?.map(
                  (item) => {
                    return vehInfoDesign(
                      selectedVehicleData,
                      vehiclePerformanceProfileDataLabels?.profile,
                      item,
                      true
                    );
                  }
                )}
                <div
                  onClick={() => handleNavVehicleSettings()}
                  className="vehicle-profile-nav-settings"
                >
                  To edit this information, please visit vehicle settings.
                </div>
              </div>
            </div>
            <div className="vehicle-profile-card2">
              <div className="vehicle-profile-card2-top">
                <div className="vehicle-profile-card-title">Trip Details</div>
                <div className="vehicle-profile-card1-content">
                  <div className="vehicle-profile-info-group1">
                    <div className="vehicle-profile-info-group-label">
                      Drive Status
                    </div>
                    <div
                      className={
                        selectedVehicleData?.data?.drive_status
                          ? "vehicle-profile-drive-status-icon"
                          : "vehicle-profile-drive-status-icon-inactive"
                      }
                    >
                      {selectedVehicleData?.data?.drive_status
                        ? "Active"
                        : "Inactive"}
                    </div>
                    {driveStatusVehicleProfile?.blurb}
                  </div>
                  {Object.keys(
                    vehiclePerformanceProfileDataLabels?.trip_details
                  )?.map((item) => {
                    return vehInfoDesign(
                      selectedVehicleData,
                      vehiclePerformanceProfileDataLabels?.trip_details,
                      item
                    );
                  })}
                </div>
              </div>
              <div className="vehicle-profile-card2-bottom">
                <div className="vehicle-profile-card-title">Fuel Details</div>
                <div className="vehicle-profile-card1-content">
                  {Object.keys(
                    vehiclePerformanceProfileDataLabels?.fuel_details
                  )?.map((item) => {
                    return vehInfoDesign(
                      selectedVehicleData,
                      vehiclePerformanceProfileDataLabels?.fuel_details,
                      item
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="vehicle-profile-card3">
              <div className="vehicle-profile-card-title">Analytics</div>
              <div className="vehicle-profile-card1-content">
                <p className="vehicle-profile-card-3-chart-label">
                  {carbonRatingLabel} Emissions Performance
                </p>
                <div className="vehicle-profile-card-3-chart">
                  <GaugeChart
                    ratingValue={selectedVehicleData?.data?.rating}
                    fontSize="1.125rem Arial"
                  />
                </div>
                {Object.keys(
                  vehiclePerformanceProfileDataLabels?.analytics
                )?.map((item) => {
                  return vehInfoDesign(
                    selectedVehicleData,
                    vehiclePerformanceProfileDataLabels?.analytics,
                    item
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
