// Functionality: This file exports the IndustryCarbonRatingCard component which is used in the OverviewPage component. The component is a card including a gauge chart that displays the carbon rating of the industry.
import { useContext } from "react";
import OverviewContext from "../../context/OverviewProvider";
import DateContext from "../../context/DateProvider";
import GaugeChart from "../../visualizations/GaugeChart";
import SwitchCarousel from "../../components/SwitchCarousel";
import Skeleton from "react-loading-skeleton";
import { industryCarbonRatingTitle } from "../../constants/ICRConfig";
import { industryCarbonRatingTile } from "../../constants/TileConfig";
import useLabelCarbonRating from "../../hooks/use-labelCarbonRating";
import { AuthContext } from "../../context/AuthProvider";

export default function IndustryCarbonRatingCard({ dateValues, windowSize }) {
  // Const: Initialize Context
  const { industryCarbonRating } = useContext(OverviewContext);

  // Const: Initialize Context - Dates
  const { applicationDates, setApplicationDates } = useContext(DateContext);

  const { carrierName } = useContext(AuthContext); // Stored Email For Help Page
  const isSCSCustomer = ["Customer1", "Customer2", "Customer3"]?.includes(
    carrierName
  );
  // Const: Label Application Dates
  const applicationDatesLabel = "industryCarbonRating";

  // Const: CSS initial naming convention prefix
  const cssPrefix = "industry-carbon-rating";

  // Const: plain text label to display below the plot
  const plainTextLabel = useLabelCarbonRating(industryCarbonRating?.value);

  // Determine Which Date to Show
  let todaysDate = new Date();
  const now = new Date();
  const utcNow = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  );

  todaysDate = utcNow;

  const showCurrentMonth =
    parseInt(
      todaysDate.toISOString().split("T")[0]?.split("-")?.slice(-1)?.[0]
    ) > 2 || isSCSCustomer;

  const showIndustryCarbon =
    parseInt(
      todaysDate.toISOString().split("T")[0]?.split("-")?.slice(-1)?.[0]
    ) > 15 || isSCSCustomer;

  return (
    <>
      {!industryCarbonRating?.loaded ? (
        <Skeleton height={"350px"} width={"90%"} />
      ) : (
        <div id={`${cssPrefix}-container`}>
          <div className={`${cssPrefix}-title card-title`}>
            {industryCarbonRatingTitle}
            {industryCarbonRatingTile?.blurb}
            {industryCarbonRatingTile?.legend}
          </div>
          <div className="industry-carbon-rating-visual-group">
            <GaugeChart
              ratingValue={industryCarbonRating?.value}
              fontSize={windowSize.width ? "3rem Arial" : "1.5rem Arial"}
            />
            <div className={`${cssPrefix}-plain-text-label font-small`}>
              {plainTextLabel}
              <p>Emissions Performance</p>
            </div>
          </div>
          <div className="group-switch-carousel">
            <SwitchCarousel
              values={
                !showCurrentMonth
                  ? dateValues
                  : showIndustryCarbon
                  ? dateValues
                  : dateValues?.slice(0, -1)
              }
              applicationDates={applicationDates}
              setApplicationDates={setApplicationDates}
              applicationDatesLabel={applicationDatesLabel}
              fillArrowColor={"#66C743"}
              switchCarouselValueClassName="group-switch-carousel-value"
            />
          </div>
        </div>
      )}
    </>
  );
}
